export const displayBannerPositions = ['FEED_0', 'FEED_1', 'MOBILE'];
export const refreshBannerPositions = ['FEED_0', 'FEED_1'];

export const displayCookies = {
  ADOBE_IDENTIFIER: 'aam_uuid',
  RSI_SEGMENTS: 'rsi_segs',
};

export const bannerPositionsInFeeds = {
  FEED_0: {
    key: 'home_top',
    isCalled: false,
  },
  MOBILE: {
    key: 'home_top',
    isCalled: false,
  },
  FEED_1: {
    key: 'home_right',
    isCalled: false,
  },
};

export const bannerTargetsInFeeds = {
  FEED_0: 'banner-top-dt',
  MOBILE: 'banner-top-dt',
  FEED_1: 'banner-right',
};

export const heightForBannerPositionInFeeds = 780;

export const bannerItemPositionsInFeeds = {
  WITH_DOUBLE_TILE_MP_CARD: 8,
  WITH_SINGLE_TILE_MP_CARD: 9,
  IN_LONG_SCREEN: 10,
  IN_SHORT_SCREEN: 5,
};

export const minViewPortSizeForBannersInFeeds = 1320;
