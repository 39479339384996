import React, { Fragment, useCallback, useContext, useMemo } from 'react';
import { CardCollection, Button, Spinner } from '@hz-design-system/web-ui';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Cookies from 'js-cookie';

import { TFeedItem } from 'types/TFeeds';
import { TMpCard } from 'types/TMpCard';

import FeedItem from './FeedItem';
import FeedItemListSkeleton from './FeedItemListSkeleton';

import { bannerItemPositionInFeeds } from '../Banners/utils/banners';
import BannerContainer from '../Banners/BannerContainer';

import { trackGAEvent } from '../../../../utils/gaTracking';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';

import Classes from './FeedItemList.scss';
import MpCard from '../Marketing/MpCard';

/**
 * On average top 2 items are immediately visible on mobile, so lazy loading should be disabled for them.
 */
const FIRST_VISIBLE_ITEMS = 2;

const isInFirstVisibleViewport = (itemIndex: number): boolean => itemIndex < FIRST_VISIBLE_ITEMS;

type TFeedsItemsProps = {
  activeFeedIndex: number;
  items: TFeedItem[];
  isLoading: boolean;
  hasNextPage: boolean;
  onLoadMore: (number?) => void;
  feedType: string;
  mpCards?: TMpCard[];
  onMpCardClick: Function;
};

/**
 * Banner position cn be calculated only client-side
 *
 * `typeof window !== 'undefined'` check is used because client-side only code is executed outside of `useEffect`
 * `useEffect` is not used here to prevent one extra re-render
 */
const bannerItemPositionInFeedsOnClient = (isMpCardSingleTileEnabled: boolean, isMpCardDoubleTileEnabled: boolean) => {
  return typeof window !== 'undefined'
    ? bannerItemPositionInFeeds(isMpCardSingleTileEnabled, isMpCardDoubleTileEnabled)
    : undefined;
};

const FeedItemList = ({
  activeFeedIndex,
  items,
  hasNextPage,
  isLoading,
  onLoadMore,
  feedType,
  mpCards,
  onMpCardClick,
}: TFeedsItemsProps) => {
  const { t } = useI18nContext();
  const { experiments } = useContext(EnvironmentContext);
  const { isMpCardSingleTileEnabled, isMpCardDoubleTileEnabled } = experiments;
  const bannerItemPosition = useMemo(
    () => bannerItemPositionInFeedsOnClient(isMpCardSingleTileEnabled, isMpCardDoubleTileEnabled),
    [isMpCardSingleTileEnabled, isMpCardDoubleTileEnabled],
  );
  const isMpCardExperimentEnabled = isMpCardSingleTileEnabled || isMpCardDoubleTileEnabled;

  const infiniteRef = useInfiniteScroll<HTMLDivElement>({
    loading: isLoading,
    hasNextPage,
    onLoadMore,
    threshold: 1000,
    checkInterval: 100,
  });

  const handleItemClick = useCallback(
    (correlationId) => {
      Cookies.set('searchRequestId', correlationId);
      trackGAEvent('Clicked', feedType);
    },
    [feedType],
  );

  if (!items.length && !isLoading) {
    return <Trans className={Classes.message} i18nKey="homepage.feeds.empty" />;
  }

  const getMpCard = (position: number) => {
    if (position !== 0 || activeFeedIndex !== 0 || !isMpCardExperimentEnabled) {
      return null;
    }

    let cardId: string | null = null;
    if (isMpCardSingleTileEnabled) {
      cardId = 'mp-cards-homepage-one-tile';
    } else if (isMpCardDoubleTileEnabled) {
      cardId = 'mp-cards-homepage-two-tiles';
    }

    return mpCards?.find((card) => card.id === cardId);
  };

  return (
    <div ref={infiniteRef}>
      {Boolean(items.length) && (
        <CardCollection kind="grid">
          {items.map((item, i) => {
            const mpCard = getMpCard(i);
            return (
              <Fragment key={`${item.itemId}-${i}`}>
                {mpCard && <MpCard mpCard={mpCard} isLazy={!isInFirstVisibleViewport(i)} onClick={onMpCardClick} />}
                {bannerItemPosition === i && <BannerContainer position={`FEED_${activeFeedIndex}`} />}
                <FeedItem item={item} lazyLoadImage={!isInFirstVisibleViewport(i)} onClick={handleItemClick} />
              </Fragment>
            );
          })}
        </CardCollection>
      )}
      {hasNextPage &&
        // Render skeleton instead of spinner if the feed has no items yet to reserve the space
        (items.length ? <Spinner /> : <FeedItemListSkeleton />)}
      {!hasNextPage && (
        <div className={Classes.loadmore}>
          <Button.Secondary onClick={() => onLoadMore(0)} disabled={isLoading}>
            {t('homepage.feeds.load_more')}
          </Button.Secondary>
        </div>
      )}
    </div>
  );
};

export default FeedItemList;
